<template>
  <div class="zee-container">
    <zee-toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <zee-screen-title
      :title="'Launch Targets'"
      :route="'/solutions/zeetv/competitor-Launch-targets'"
    />

    <div class="zee-card-background">
      <div class="zee-table-heading">DataPoem Recommended Campaign Distributions (%)</div>
      <div class="zee-launch-targets-table-wrapper mt-5">
        <div class="zee-select-table-radios">
          <div class="" v-for="(item, index) in radios" :key="index">
            <input
              @input="handleTableSelect"
              style="width: 15px; height: 15px"
              type="radio"
              name="abc"
              :id="index"
            />
          </div>
        </div>
        <zee-select-table
          :tableBodyData="tableBodyData"
          :tableHeaderData="tableHeaderData"
        >
          <zee-custom-input-table
            @onInput="handleCustomInput"
            :customInputData="tableBodyData1"
          />
        </zee-select-table>
      </div>
      <div class="zee-program-save-button mt-5">
        <zee-button @onClick="handleSave" title="Next"></zee-button>
      </div>
    </div>
    <page-loader v-if="isLoading" />
  </div>
</template>

<script>
import { Button, Table, CustomInputTable, ScreenTitle } from "../components";
import Toast from "@/components/Toast/Toast.vue";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "RecomendedLaunchTargets",
  components: {
    "zee-button": Button,
    "zee-select-table": Table,
    "zee-custom-input-table": CustomInputTable,
    "zee-screen-title": ScreenTitle,
    "zee-toast": Toast,
  },
  data() {
    return {
      campaignDistribution: [],
      campaignDistribution1:[],
      campaignDistribution2:[],
      outputData: [],
      tableBodyData: [
        {
          columns: [
            { name: "Option 2" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
        {
          columns: [
            { name: "Option 3" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
        {
          columns: [
            { name: "Option 4" },
            { name: "40%" },
            { name: "30%" },
            { name: "20%" },
            { name: "10%" },
            // { name: "10%" },
            // { name: "10%" },
            // { name: "10%" },
            // { name: "10%" },
            // { name: "10%" },
          ],
        },
      ],
      tableBodyData1: [
        {
          columns: [
            { id: [null, null], name: "Custom", value: "" },
            { id: [0, 1], name: "value", value: "" },
            { id: [0, 2], name: "value", value: "" },
            { id: [0, 3], name: "value", value: "" },
            { id: [0, 4], name: "value", value: "" },
            { id: [0, 5], name: "value", value: "" },
            { id: [0, 6], name: "value", value: "" },
            { id: [0, 7], name: "value", value: "" },
            { id: [0, 8], name: "value", value: "" },
            { id: [0, 9], name: "value", value: "" },
          ],
        },
      ],
      tableHeaderData: [
        {
          columns: [
            { name: "Options" },
            { name: "week-8" },
            { name: "week-7" },
            { name: "week-6" },
            { name: "week-5" },
            { name: "week-4" },
            { name: "week-3" },
            { name: "week-2" },
            { name: "week-1" },
            { name: "week-0" },
          ],
        },
      ],
      customOptions: [
        {
          columns: [
            { name: "custom" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
            { name: "value" },
          ],
        },
      ],
      radios: [
        {
          id: 0,
        },
        {
          id: 1,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
    };
  },
  methods: {
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    handleSave() {
      let prevData = JSON.parse(localStorage.getItem("programDetails"));
      prevData[0].recommendedDistribution = this.selectedRow;

      localStorage.setItem("programDetails", JSON.stringify(prevData));

      // if (this.outputData !== undefined) {
      //   let x = this.outputData.reduce((acc, curr) => acc + curr);
      //   if (x !== 100) {
      //     this.toastData = {
      //       show: true,
      //       type: "edit",
      //       message: "Custom Distribution must sum to 100",
      //     };
      //   } else if (this.selectedRow === undefined) {
      //     this.toastData = {
      //       show: true,
      //       type: "edit",
      //       message: "Please select One Option",
      //     };
      //   }
      // } else {
      //   }
      this.$router.push("/solutions/zeetv/select-acds");
    },
    handleTableSelect(e) {
      let id = +e.target.id;

      if (id === this.tableBodyData.length) {
        let temp = [];
        for (var i = 0; i < this.tableBodyData1[0].columns.length; i++) {
          temp.push(+this.tableBodyData1[0].columns[i].value);
        }
        temp.pop();
        this.selectedRow = temp;
        // console.log(temp);
      } else {
        let temp = [];
        for (var j = 1; j < this.tableBodyData[id].columns.length; j++) {
          temp.push(+this.tableBodyData[id].columns[j].name);
        }
        this.selectedRow = temp;
      }
      // console.log(this.selectedRow);
    },
    async handleTableCreation(weeks) {
      let res = await zeeSolutions.getCompetitorLaunchTargets(weeks);
      let cdl = this.campaignDistribution.length
      if(cdl === 0){
        this.campaignDistribution = res[7].weeklyDistribution
      }
      
      this.campaignDistribution1 = res[3].weeklyDistribution
      this.campaignDistribution2 = res[4].weeklyDistribution
      // console.log("inside api call",this.campaignDistribution1, this.campaignDistribution2)
      this.tableHeaderData[0].columns = [];
      this.tableBodyData[0].columns = [];
      this.tableBodyData[1].columns = [];
      this.tableBodyData[2].columns = [];

      // this.tableBodyData[2].columns = [];
      this.tableBodyData1[0].columns = [];

      for (var i = -1; i < weeks; i++) {
        this.tableHeaderData[0].columns.push({ name: `week ${i + 1}` });
        this.tableBodyData[0].columns.push({ name: this.campaignDistribution[i] });
        this.tableBodyData[1].columns.push({ name: this.campaignDistribution1[i] });
        this.tableBodyData[2].columns.push({ name: this.campaignDistribution2[i] });

        // this.tableBodyData[2].columns.push({ name: "" });
        this.tableBodyData1[0].columns.push({ name: "", id: [0, i] });
      }
      this.tableHeaderData[0].columns[0].name = "Options";
      // this.tableBodyData[0].columns[0].name = "Default";
      // this.tableBodyData[1].columns[0].name = "DP Recommended 1";
      // this.tableBodyData[2].columns[0].name = "DP Recommended 2";
      if(cdl === 0){
        
        this.tableBodyData[0].columns[0].name = "DP Recommended 1";
        this.tableBodyData[1].columns[0].name = "DP Recommended 2";
        this.tableBodyData[2].columns[0].name = "DP Recommended 3";
        // console.log("in the if")
      }else{
        this.tableBodyData[0].columns[0].name = "Default";
        this.tableBodyData[1].columns[0].name = "DP Recommended 1";
        this.tableBodyData[2].columns[0].name = "DP Recommended 2";
        // console.log("in the else")
      }

      this.tableBodyData1[0].columns[0].name = "Custom";
    },
    handleCustomInput(e) {
      let id = e.id;
      this.tableBodyData1[id[0]].columns[id[1]].value = e.event.target.value;

      const output = [];
      this.selectedRow = output;
      for (var i = 0; i < this.tableBodyData1[0].columns.length - 1; i++) {
        output.push(+this.tableBodyData1[0].columns[i].value);
        if (output.length !== 0) {
          this.outputData = output;
        }
      }
    },
  },
  created() {
    let x = JSON.parse(localStorage.getItem("programDetails"));
    this.campaignDistribution = x[0].campaignDistribution;
    let data = +sessionStorage.getItem("weeks");
    this.handleTableCreation(data);
    this.radios = [];
    for (var i = 0; i < this.tableBodyData.length + 1; i++) {
      this.radios.push({ id: i });
    }
  },
};
</script>

<style>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  height: 100%;
}
.zee-card-background {
  padding: 3rem;
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  margin-bottom: 54px;
  border-radius: 15px;
}
.zee-program-save-button {
  display: flex;
  justify-content: flex-end;
}

.zee-recomended-launch-custom-table-body {
  margin-left: 15px;
}

.zee-select-table-radios {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-top: 8.5rem;

  gap: 3.5rem;
}

.zee-launch-targets-table-wrapper {
  display: flex;
  width: 100%;
}

.zee-table-heading {
  margin-left: 5%;
  font-size: 2rem;
  color: #000;
  font-weight: bolder;
}
</style>
